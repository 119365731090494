<template>
  <TsModalTrolleyDelivery>
    <template #title-tag>
      <TsTag
        v-if="tagLabel.length"
        :label="tagLabel"
        :variant="titleTagVariant"
        rounded
        outlined
        append-class="outline-0 ms-2 font-semibold"
        size="sm"
      />
      <span v-else></span>
    </template>

    <template v-if="products.length">
      <TsMedia
        v-for="product in products"
        :key="product.code"
        append-class="border-b border-natural-light-grey"
      >
        <TsMediaStart append-class="mt-4 mx-2 pl-2 bg-white rounded-xl pr-2">
          <NuxtImg
            class="rounded-lg"
            :src="product.image"
            :alt="product.slug"
            width="65"
            height="65"
          />
        </TsMediaStart>
        <TsMediaContent append-class="py-4">
          <TsRow append-class="m-0 pr-7">
            <TsTypography v-if="product.full_name" size="sm" append-class="mb-0 text-idle-black">
              <span class="font-bold">{{
                product?.full_name.split(" ")[0]
              }}</span>
              {{ product?.full_name?.split(" ").slice(1).join(' ') }}
            </TsTypography>
          </TsRow>
          <TsRow append-class="m-0 py-1 gap-5">
            <TsTypography size="sm" append-class="mb-0 text-natural-grey">
              Quantity &nbsp; {{ product?.quantity }}
            </TsTypography>
            <TsTypography size="sm" append-class="mb-0 text-natural-grey">
              {{ product?.prices?.formatted.net }}
            </TsTypography>
          </TsRow>
          <TsRow v-if="!tagLabel.length">
            <TsTag
              :label="TrolleyChannel[product.channel]"
              variant="info"
              rounded
              size="sm"
            />
          </TsRow>
        </TsMediaContent>
      </TsMedia>
    </template>

    <template v-else> Loading... </template>
  </TsModalTrolleyDelivery>
</template>

<script lang="ts" setup>
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

type Props = {
  products: TrolleyItemVariant[];
  dataTestid: string;
  branch?: string;
};

const props = withDefaults(defineProps<Props>(), {});

const tagLabel = computed(() => {
  const allChannels = props.products.map((product) => product.channel);

  if (allChannels.every((channel) => channel === TrolleyChannel.Collection)) {
    return `Collection at ${props.branch ?? "my branch"}`;
  }

  if (
    allChannels.every((channel) => channel === TrolleyChannel.NextDayCollection)
  ) {
    return `Next Day Collection at ${props.branch ?? "my branch"}`;
  }

  if (allChannels.every((channel) => channel === TrolleyChannel.Delivery)) {
    return "Delivery";
  }

  if (allChannels.every((channel) => channel === TrolleyChannel.Directship)) {
    return "Directship";
  }

  return "";
});

const titleTagVariant = computed(() =>
  tagLabel.value === "Delivery" || tagLabel.value === "Directship"
    ? "success"
    : "info"
);
</script>
